import revive_payload_client_4sVQNw7RlN from "/Users/zinomex/Desktop/Telescope/observer-ui/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/zinomex/Desktop/Telescope/observer-ui/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/zinomex/Desktop/Telescope/observer-ui/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/zinomex/Desktop/Telescope/observer-ui/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/zinomex/Desktop/Telescope/observer-ui/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/zinomex/Desktop/Telescope/observer-ui/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/zinomex/Desktop/Telescope/observer-ui/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/zinomex/Desktop/Telescope/observer-ui/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/zinomex/Desktop/Telescope/observer-ui/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import clerk_LDF0df1ZE5 from "/Users/zinomex/Desktop/Telescope/observer-ui/node_modules/vue-clerk/dist/nuxt/runtime/plugins/clerk.js";
import plugin_WLsn00x1qh from "/Users/zinomex/Desktop/Telescope/observer-ui/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_UYiXMU8ZyN from "/Users/zinomex/Desktop/Telescope/observer-ui/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import primevue_plugin_egKpok8Auk from "/Users/zinomex/Desktop/Telescope/observer-ui/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/Users/zinomex/Desktop/Telescope/observer-ui/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import vue_clerk_NNPdTlBnWo from "/Users/zinomex/Desktop/Telescope/observer-ui/plugins/vue-clerk.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  clerk_LDF0df1ZE5,
  plugin_WLsn00x1qh,
  plugin_client_UYiXMU8ZyN,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  vue_clerk_NNPdTlBnWo
]